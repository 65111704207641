<template>
<div>

  <div v-html="html">
    

   
  </div>
</div>
</template>

<script>
  
  export default {
    name: 'Terms',
    data: () => ({
 
      html: ''
    }),
    created() {

     /* this.$http.getUrl('https://www.serprogreso.com').then(response => {
        console.log(response)
      })*/
    },

    methods: {


    }
  }
</script>
